@import 'src/vars.scss';

.selectType {
  display: flex;
  gap: 10px;
  width: 100%;
  justify-content: space-between;
  overflow-x: auto;

  .button {
    all: unset;
    padding: 10px 16px;
    color: #66707A;
    border-radius: 6px;
    background-color: #F5F5F5;
    font-weight: 400;
    font-size: 16px;
    cursor: pointer;
  }

  .selected {
    background-color: $primarColor;
    color: #F5F5F5;
  }
}
