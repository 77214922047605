.textField {
  background-color: #F5F5F5;
  display: flex;
  align-items: center;
  height: 50px;
  min-height: 50px;
  // border: 2px #DCDCDC solid;
  border-radius: 4px;
  width: 100%;
  gap: 12px;

  img {
    margin-left: 18px;
    max-width: 20px;
  }

  input {
    background-color: #F5F5F5;
    height: 100%;
    width: 100%;
    outline: none;
    border: none;
  }

  input::placeholder {
    font-weight: 400;
    font-size: 12px;
  }

  input:focus {
    font-weight: 600;
  }
}