.autocomplete {
  background-color: #F5F5F5;
  position: relative;
  display: flex;
  align-items: center;
  height: 50px;
  border: 2px #DCDCDC solid;
  border-radius: 4px;
  width: 100%;
  gap: 12px;

  .options {
    position: absolute;
    width: 100%;
    top: calc(100% + 10px);
    display: flex;
    flex-direction: column;
    border-radius: 3px;
    background-color: #F5F5F5;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    max-height: 150px;
    overflow-y: auto;
    z-index: 500;

    .option {
      cursor: pointer;
      color: #979C9E;
      padding: 5px 0;

      &:hover {
        background-color: #E5E8EC;
      }
    }
  }

  img {
    margin-left: 18px;
  }

  input {
    background-color: #F5F5F5;
    height: 100%;
    width: 100%;
    outline: none;
    border: none;
  }

  input::placeholder {
    font-weight: 400;
    font-size: 12px;
  }

  input:focus {
    font-weight: 600;
  }

  .wrong {
    color: #FF0000;
  }

  .correct {
    color: #036703;
  }
}