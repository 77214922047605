.container {
  border: 2px solid #DCDCDC;
  border-radius: 4px;
  background: #F5F5F5;
  display: flex;
  align-items: center;

  img {
    margin-left: 18px;
  }

  p{
    font-weight: 400;
    font-size: 12px;
    color: #484848;
    margin-left: 15px;
    width: 40%;
  }

  .select {
    height: 100%;
    all: unset;
    box-sizing: border-box;
    font-weight: 400;
    font-size: 16px;
    color: #979C9E;
    padding: 12px;
    padding-right: 50px;


    background: #F5F5F5 url("./arrow_down.png") no-repeat center right 10px;
    -webkit-appearance: none;


    option {
      padding: 30px;
    }
  }
}

