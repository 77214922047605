@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Inter, serif;
}

html {
  font-size: calc(13px + (16 - 13) * ((100vw - 1080px) / (1920 - 1080)));
}

body {
  background-color: #d9e4e2;
}

.image-block {
  a {
    display: block;
  }

  img {
    display: block;
    border-radius: 10px;
  }
}

.tree {
  // margin-top: 12px;
  margin-bottom: 10px;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  // border-left: 2px solid #666666;
  margin-left: 12px;
  position: relative;

  .person {
    display: flex;
    // align-items: center;
    gap: 4px;
    // transform: translateY(calc(50% - 1px));
    position: relative;

    &:last-child:not(:only-child) {
      &:before {
        display: none;
      }
    }

    &:only-child {
      &:before {
        bottom: auto;
        display: block;
        height: 26px;
      }
    }

    &:before {
      position: absolute;
      left: 0;
      top: 0;
      bottom: -26px;
      content: '';
      background: #666;
      width: 1px;
    }

    .line2 {
      width: 24px;
      height: 1px;
      background-color: #666666;
      margin-top: 26px;
    }

    .grid {
      display: grid;
      grid-template:
        'profile name'
        'profile type';
      grid-gap: 3px;

      .MuiAvatar-root {
        margin-top: 4px;
      }

      .profileImage {
        grid-area: profile;
        margin-right: 3px;
      }

      .name {
        font-weight: 600;
        font-size: 70%;
        color: #16191c;
        grid-area: name;
      }

      .type {
        grid-area: type;
        font-weight: 300;
        font-size: 50%;
        color: #16191c;
      }
    }
  }
}

.CircularProgressbar {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.circularBarTextBelow {
  padding-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.CircularProgressbar p {
  font-size: 10px;
  width: 60px;
  white-space: unset !important;
}

.CircularProgressbarGroup {
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding-top: 5px;
  margin-bottom: 30px;
  width: 90%;
}

.SideBarFooterText {
  display: grid;
  place-content: center;
  // height: 20%;
  padding: 20px 0px;
  font-size: 14px;
  font-weight: 700;
}

mux-player::part(layer) {
  border-radius: 12px;
}

mux-player::part(bottom play button) {
  border-bottom-left-radius: 12px;
}

mux-player::part(bottom fullscreen button) {
  border-bottom-right-radius: 12px;
}

.fraction {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

.fraction > span {
  display: block;
}

.fraction span.fraction--denominator {
  border-top: thin solid black;
}

.fraction span.fraction--bar {
  display: none;
}

.mathquill {
  font-size: 16px;
  display: inline-block;
}

.selected_reaction {
  background-color: #d9e4e2;
}

.btn-primary,
.btn-secondary,
.btn-outline {
  background-color: #4cbfa7;
  display: inline-block;
  border: none;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  border-radius: 10px;
  padding: 10px;
  text-decoration: none;
  transition: all 0.4s ease;
  min-width: 120px;

  &:hover {
    background-color: darken($color: #4cbfa7, $amount: 20%);
  }
}

.btn-secondary {
  background-color: #c73e9d;

  &:hover {
    background-color: darken($color: #c73e9d, $amount: 20%);
  }
}

.btn-outline {
  background-color: #d9e4e2;
  color: #16191c;

  &:hover {
    background-color: darken($color: #d9e4e2, $amount: 20%);
  }
}

.img-cross {
  border-radius: 0 !important;
}

.form-area {
//  padding: 0 120px;

 .textField_textField__rjYw5 {
  margin-bottom: 10px;
 }

 .dark-text {
  display: block;
  font-weight: 700;
 }
}

.checkbox-area {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .checkbox {
    margin: 0;
  }

  span {
    padding: 0 10px 0 0;
    font-size: 32px;

    @media (max-width: 1599px) {
      font-size: 18px;
    }

    @media (max-width: 480px) {
      padding: 0 4px 0 0;
      font-size: 10px;
    }
  }

  .password {
    font-size: 32px;
    line-height: 1;
    font-weight: 400;
    color: #c73e9d;

    @media (max-width: 1599px) {
      font-size: 18px;
    }

    @media (max-width: 480px) {
      font-size: 10px;
    }
  }
}