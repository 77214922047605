.selecetType {
  padding: 43px 25px !important;

  .title {
    font-weight: 600;
    font-size: 24px;
    max-width: 368px;
    margin-bottom: 30px;
  }

  .option {
    display: flex;
    gap: 20px;
    width: 100%;
    border-radius: 16px;
    align-items: center;
    border: #f5eeee 2px solid;

    .iconContainer {
      min-width: 92px;
      min-height: 92px;
      border-bottom-left-radius: 16px;
      border-top-left-radius: 16px;

      display: flex;
      justify-content: center;
      align-items: center;
    }

    h2 {
      font-weight: 600;
      font-size: 20px;
    }

    .furtherButton {
      all: unset;
      margin-left: auto;
      margin-right: 20px;
      cursor: pointer;
    }

    .furtherButton:hover {
      filter: brightness(0.9);
    }

    .furtherButton:active {
      transform: scale(0.9);
    }
  }
}

.register {
  padding: 24px 52px !important;

  .title {
    font-weight: 500;
    font-size: 18px;
    color: #131515;
  }

  .text {
    color: #131515;
    font-weight: 600;
    font-size: 24px;
    margin-bottom: 50px;
  }

  .passwords {
    width: 100%;
    display: flex;
    gap: 20px;
  }
}
