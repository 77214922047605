.radioButtons {
  display: flex;
  width: 100%;
  gap: 20px;

  .radioButton {
    display: flex;
    align-items: center;
    gap: 10px;

    p {
      font-weight: 500;
      font-size: 12px;
      color: #484848;
    }

    input {
      display: none;
    }

    label {
      width: 20px;
      height: 20px;
      min-width: 20px;
      min-height: 20px;
      border: #1F7BF4 2px solid;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .checkmark {
      width: calc(100% - 5px);
      aspect-ratio: 1/1;
      background-color: #1F7BF4;
      border-radius: 50%;
      opacity: 0;
      transition: opacity 0.2s ease-in-out;
    }

    label:hover {
      border: #256cd2 2px solid;
    }

    label input:checked + .checkmark {
      display: inline-block;
      opacity: 1;
    }
  }
}