@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
* {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: Inter, serif;
}
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
html {
  font-size: calc(13px + 3 * (100vw - 1080px) / 840);
}

body {
  background-color: #d9e4e2;
}

.image-block a {
  display: block;
}
.image-block img {
  display: block;
  border-radius: 10px;
}
mux-player {
  --media-object-fit: cover;
  --media-object-position: center;
}
.__Latex__ .base {
  /* display: contents !important;
  word-wrap: break-word !important; */
}
.tree {
  margin-bottom: 10px;
  margin-right: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-left: 12px;
  position: relative;
}
.tree .person {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 4px;
  position: relative;
}
.tree .person:last-child:not(:only-child):before {
  display: none;
}
.tree .person:only-child:before {
  bottom: auto;
  display: block;
  height: 26px;
}
.tree .person:before {
  position: absolute;
  left: 0;
  top: 0;
  bottom: -26px;
  content: '';
  background: #666;
  width: 1px;
}
.tree .person .line2 {
  width: 24px;
  height: 1px;
  background-color: #666666;
  margin-top: 26px;
}
.tree .person .grid {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: auto;
  grid-template: 'profile name' 'profile type';
  grid-gap: 3px;
}
.tree .person .grid .MuiAvatar-root {
  margin-top: 4px;
}
.tree .person .grid .profileImage {
  -ms-grid-row: 1;
  -ms-grid-row-span: 3;
  -ms-grid-column: 1;
  grid-area: profile;
  margin-right: 3px;
}
.tree .person .grid .name {
  -ms-grid-row: 1;
  -ms-grid-column: 3;
  font-weight: 600;
  font-size: 70%;
  color: #16191c;
  grid-area: name;
}
.tree .person .grid .type {
  -ms-grid-row: 3;
  -ms-grid-column: 3;
  grid-area: type;
  font-weight: 300;
  font-size: 50%;
  color: #16191c;
}

.CircularProgressbar {
  width: 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.circularBarTextBelow {
  padding-top: 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.CircularProgressbar p {
  font-size: 10px;
  width: 60px;
  white-space: unset !important;
}

.CircularProgressbarGroup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: start;
  padding-top: 5px;
  margin-bottom: 30px;
  width: 90%;
}

.SideBarFooterText {
  display: -ms-grid;
  display: grid;
  place-content: center;
  padding: 20px 0px;
  font-size: 14px;
  font-weight: 700;
}

mux-player::part(layer) {
  border-radius: 12px;
}

mux-player::part(bottom play button) {
  border-bottom-left-radius: 12px;
}

mux-player::part(bottom fullscreen button) {
  border-bottom-right-radius: 12px;
}

.fraction {
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}

.fraction > span {
  display: block;
}

.fraction span.fraction--denominator {
  border-top: thin solid black;
}

.fraction span.fraction--bar {
  display: none;
}

.mathquill {
  font-size: 16px;
  display: inline-block;
}

.selected_reaction {
  background-color: #d9e4e2;
}

.btn-primary,
.btn-secondary,
.btn-outline {
  background-color: #4cbfa7;
  display: inline-block;
  border: none;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  border-radius: 10px;
  padding: 10px;
  text-decoration: none;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
  min-width: 120px;
}
.btn-primary:hover,
.btn-secondary:hover,
.btn-outline:hover {
  background-color: #2b7a69;
}

.btn-secondary {
  background-color: #c73e9d;
}
.btn-secondary:hover {
  background-color: #7b2460;
}

.btn-outline {
  background-color: #d9e4e2;
  color: #16191c;
}
.btn-outline:hover {
  background-color: #9dbab4;
}

.img-cross {
  border-radius: 0 !important;
}

.form-area {
  padding: 0 120px;
}
.form-area .textField_textField__rjYw5 {
  margin-bottom: 10px;
}
.form-area .dark-text {
  display: block;
  font-weight: 700;
}
