.centerContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  position: absolute;
  gap: 90px;
  width: 100vw;
  height: 100vh;
}

.loginButton {
  all: unset;
  box-sizing: border-box;
  padding: 14px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px;
  color: #fefefe;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 100%;

  &:hover {
    cursor: pointer;
    filter: brightness(0.9);
  }
}

.whiteContainerBox {
  display: flex;
  flex-direction: column;
  gap: 20px;

  background-color: white;
  text-align: center;
  padding: 24px;
  width: clamp(1px, 100%, 560px);
  // border-radius: 32px;

  .orField {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;

    .line {
      width: 40%;
      height: 1px;
      background-color: #000000;
    }

    p {
      font-weight: 700;
      font-size: 14px;
      color: #000;
    }
  }
}

@media screen and (max-width: 1076px) {
  .centerContainer {
    .handyImage {
      order: 2;
    }
  }
}

@media screen and (max-width: 400px) {
  .centerContainer {
    .handyImage {
      display: none;
    }

    .whiteContainerBox {
      padding: 10px !important;
    }
  }
}

.uploadImage {
  margin-top: 20px;
  position: relative;
  cursor: pointer;
  width: 100%;
  max-height: 140px;

  img {
    max-height: 140px;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
    aspect-ratio: 1/1;
  }

  .editIcon {
    position: absolute;
    right: 10px;
    bottom: 10px;
  }

  input {
    display: none;
  }
}
.container {
  display: flex;
  margin: 30px;
  height: calc(100vh - 60px);
  width: calc(100vw - 60px);
  background-color: #e9f6f3;
  border-radius: 35px;
  @media (max-width: 600px) {
    margin: 15px;
    height: calc(100vh - 30px);
    width: calc(100vw - 30px);
  }
  .studentsContainer {
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    .arrow {
      display: flex;
      width: 100%;
      justify-content: start;
      height: 5%;
      position: absolute;
      top: 10px;
      left: 10px;
      img {
        height: 50px;
      }
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
}

.loginContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4%;
  height: 100%;
  padding: 20px 0px;
  align-items: center;

  background-color: white;
  text-align: center;
  width: clamp(1px, 100%, 560px);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  border-radius: 32px;
  .logo {
    height: 10vh;
    display: flex;
    justify-content: center;

    img {
      height: 100%;
      object-fit: cover;
    }
  }
}
.signupContainer {
  gap: 10px;
  overflow-y: scroll;

  @media (min-width: 1800px) {
    gap: 20px !important;
  }
  @media (max-width: 600px) {
    gap: 20px !important;
  }
}
.signupContainerDetails {
  @media (max-width: 600px) {
    gap: 10px !important;
  }
}
.imageLeft {
  height: 80%;
  img {
    height: 100%;
  }
}
.studentContainer {
  justify-content: flex-start !important;
  gap: 80px;

  @media (max-width: 767px) {
    gap: 20px;
  }
}
.qr {
  background-color: #dcdcdc;
  border-radius: 31px;
  justify-content: center;
  align-items: center;
  gap: 22px;
  flex-direction: column;
  width: 60%;
  padding: 22px 0px;
  img {
    width: 30%;
  }
}
.homelayout {
  padding: 0 20px;
  height: 100vh;

  .container {
    margin: 0 auto;
    padding: 0 15px;
  }

  .mainsignup {
    background: #e9f6f3;
    border-radius: 35px;
    min-height: 100%;
    position: relative;

    @media (max-width: 767px) {
      padding: 20px 0 0;
    }
  }
  .primary_bg_btn_outlined {
    &.MuiButton-root {
      border: 4px solid #c73e9d !important;
      color: #c73e9d;
      box-shadow: none;
      font-weight: 500;

      @media screen and (max-width: 600px) {
        color: #c73e9d !important;
        border: 4px solid #c73e9d !important;
      }
    }
  }
  .mainarea {
    padding: 20px 40px;
    background: unset !important;
    min-height: 100vh;
    position: relative;

    @media (max-width: 767px) {
      padding: 20px 0 0;
    }
  }

  .profilelists {
    max-width: 1424px;
    margin: 0 auto;
    position: relative;
  }
}

.usertitle {
  font-size: 120px !important;
  font-weight: 600 !important;
  color: #000 !important;
  margin-bottom: 20px;

  @media (max-width: 1599px) {
    font-size: 80px !important;
  }

  @media (max-width: 991px) {
    font-size: 50px !important;
  }

  @media (max-width: 767px) {
    font-size: 30px !important;
    margin-bottom: 10px;
  }
}

.userlist {
  font-size: 48px !important;
  margin-bottom: 10px !important;
  font-weight: 700 !important;
  color: #000 !important;

  @media (max-width: 1599px) {
    font-size: 32px !important;
  }

  @media (max-width: 1199px) {
    font-size: 24px !important;
  }

  @media (max-width: 767px) {
    font-size: 18px !important;
  }

  @media (max-width: 480px) {
    font-size: 16px !important;
  }
}
.listwrap {
  display: flex;
  margin: 0 -10px;

  @media (max-width: 767px) {
    flex-wrap: wrap;
    padding: 0 40px;
  }
}

.headerWrap {
  @media (max-width: 767px) {
    padding: 0 10px;
  }
}

.list {
  width: 25%;
  padding: 0 10px;
  border-radius: 17px;

  @media (max-width: 767px) {
    width: 50%;
    margin-bottom: 50px;
  }

  @media (max-width: 480px) {
    width: 100%;
    margin-bottom: 20px;
  }

  .imageblock {
    border: 5px solid #c73e9d;
    border-radius: 17px;
    position: relative;

    img {
      display: block;
      border-radius: 12px;
    }
  }

  .name {
    font-size: 40px;
    font-weight: 600;
    text-align: center;
    color: #000;
    letter-spacing: 2px;

    @media (max-width: 1599px) {
      font-size: 32px;
    }

    @media (max-width: 1199px) {
      font-size: 24px;
    }

    @media (max-width: 991px) {
      font-size: 16px;
    }
  }
}
.parent-layout {
  height: 100vh;
  overflow: hidden;
}
.parentswrap {
  height: calc(100% - 60px);
  margin: 30px;
  background: #e9f6f3;
  border-radius: 35px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 991px) {
    flex-wrap: wrap;
  }

  .col {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 90%;

    @media (max-width: 1023px) {
      width: 100%;
    }

    .imagearea {
      display: flex;
      justify-content: center;
      height: 100%;
      width: 90%;
      border-radius: 24px;
      overflow: hidden;

      @media (max-width: 991px) {
        max-width: 200px;
        margin: 30px auto 0;
      }

      .image {
        width: 100%;
        border-radius: 24px;
        object-fit: cover;
        display: block;
        @media (max-width: 991px) {
          max-width: 200px;
        }
      }
    }
  }

  .whiteContainerBox {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    padding-top: 30px;

    @media (max-width: 991px) {
      width: 100%;
      min-height: 100%;
    }
  }

  .headerSmall {
    font-size: 24px;
    font-weight: 500;
    color: gray;
  }
  .header {
    color: #000;
    font-size: 3vw;
    font-weight: 600;
  }
  .intro {
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: #000;
    text-align: left;
    margin-bottom: 10px;

    @media (max-width: 1599px) {
      font-size: 32px;
    }

    @media (max-width: 1199px) {
      font-size: 22px;
    }

    @media (max-width: 991px) {
      font-size: 18px;
    }

    @media (max-width: 576px) {
      font-size: 14px;
    }
  }

  .formtitle {
    font-size: 32px;
    line-height: 1;
    font-weight: 600;
    color: #c44297;
    text-align: left;

    @media (max-width: 767px) {
      font-size: 18px;
    }
  }
}
.MuiOutlinedInput-input {
  @media (max-width: 767px) {
    font-size: 13px !important;
  }
}
.profileDetailsContainer {
  @media (max-width: 767px) {
    width: 100% !important;
    padding: 10px !important;
  }
  .avatarLogo {
    @media (max-width: 600px) {
      position: absolute;
      top: 30px !important;
      display: none !important;
    }
  }
}
.detailsContainer {
  width: 50% !important;
  padding: 30px 60px !important;
  @media (max-width: 767px) {
    width: 100% !important;
    padding: 30px 20px !important;
  }
}
.qrContainer {
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  height: calc(100vh);
  background: #9db0c1;
  position: relative;

  .avatar {
    width: 300px;
    height: 300px;
    position: relative;
    text-align: center;
    video,
    img {
      position: absolute;
    }
    .qrCode {
      width: 90%;
      height: 90%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -57%) rotateY(180deg);
    }
  }
  .arrowFloat {
    display: flex;
    width: 100%;
    justify-content: start;
    height: 5%;
    position: absolute;
    top: 10px;
    left: 10px;
    img {
      height: 40px;
    }
  }
}
.profileAvatarContainer {
  background: #e9f6f3;
  padding: 44px;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  height: calc(100vh);
  .avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #fff;
    width: 30%;
    padding: 60px 0px;
    gap: 30px;
    img {
      width: 13vw;
      height: 13vw;
      object-fit: cover;
      @media (max-width: 576px) {
        width: 20vw;
        height: 20vw;
      }
    }
  }
  .avatarLogo {
    position: absolute;
    left: 44px;
    top: 44px;
    height: 100px;
    img {
      height: 100%;
    }
    @media (max-width: 576px) {
      top: 13%;
      left: 50%;
      height: 50px;
      transform: translate(-50%, -50%);
    }
  }
}
.buttowrap {
  display: flex;
  margin: 0 -10px;
  transform: scale(0.9);

  @media (max-width: 1600px) {
    margin-top: -20px;
    transform: scale(0.7);
  }
  @media (max-width: 576px) {
    // flex-wrap: wrap;
  }

  .button {
    // width: 33%;
    padding: 0 10px;

    @media (max-width: 576px) {
      width: 50%;
    }

    span {
      display: block;
      font-weight: 600;
    }
  }

  .appstatus {
    font-size: 20px;

    @media (max-width: 1599px) {
      font-size: 16px;
    }
    @media (max-width: 767px) {
      display: none;
    }
  }

  .applink {
    background: #fff;
    padding: 10px;
    border-radius: 100%;
    width: 60px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 30px;
      height: 30px;
    }
  }
}

.linksattcahed {
  font-size: 32px !important;
  font-weight: 500;
  color: #666;

  @media (max-width: 1599px) {
    font-size: 24px !important;
  }

  @media (max-width: 480px) {
    font-size: 12px !important;
  }
}

.textfont {
  font-size: 40px !important;
  font-weight: 500 !important;
  line-height: 1.3 !important;
  color: #131515 !important;
  max-width: 840px;
  margin: 0 auto;

  @media (max-width: 1599px) {
    font-size: 32px !important;
  }

  @media (max-width: 1199px) {
    font-size: 24px !important;
  }

  @media (max-width: 480px) {
    font-size: 16px !important;
  }
}

.caption {
  font-size: 40px !important;
  line-height: 1.2;
  font-weight: 500 !important;
  color: #131515 !important;

  @media (max-width: 1599px) {
    font-size: 24px !important;
  }

  @media (max-width: 767px) {
    font-size: 18px !important;
  }
}

.accounttitle {
  font-size: 40px !important;
  color: #000 !important;
  font-weight: 600 !important;
  padding: 20px 0;

  @media (max-width: 1599px) {
    font-size: 22px !important;
  }

  @media (max-width: 767px) {
    font-size: 16px !important;
  }

  @media (max-width: 480px) {
    font-size: 14px !important;
    padding: 10px 0;
  }
}
