.schoolLeader {
  width: clamp(300px, 100%, 750px) !important;
  padding: 20px 150px;
  padding-bottom: 67px;

  .row {
    display: flex;
    gap: 20px;
    width: 100%;
  }

  .title {
    color: #110C1D;
    font-weight: 700;
    font-size: 24px;
  }

  p{
    font-weight: 400;
    font-size: 14px;
    max-width: 200px;
    color: #777E90;
  }
}

@media screen and (max-width: 704px) {
  .schoolLeader{
    padding: 20px 20px;
    padding-bottom: 67px;
  }
}
