.completeProfile {
  width: clamp(300px, 100%, 750px) !important;
  padding: 20px 150px;
  padding-bottom: 67px;

  .title {
    font-weight: 600;
    font-size: 24px;
  }

  .row {
    display: flex;
    gap: 20px;
    width: 100%;
  }

  .topic {
    font-weight: 400;
    font-size: 12px;
    display: flex;
    border-radius: 8px;
    padding: 8px 10px 8px 10px;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    background: #F5F5F5;
    border: 2px solid #DCDCDC;
    color: #484848;

    button {
      all: unset;
      cursor: pointer;

      :active {
        transform: scale(0.9);
      }
    }
  }
}

@media screen and (max-width: 704px) {
  .completeProfile{
    padding: 20px 20px;
    padding-bottom: 67px;
  }
}