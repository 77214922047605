.force-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}
.appbar-container {
  position: absolute;
  top: 0;
  width: 100%;
}
.modal-content h2 {
  font-size: 20px;
  font-weight: 600;
}
.modal-content .download-links:hover {
  text-decoration: underline;
}
.modal-content p {
  font-size: 14px;
  color: #000;
  margin-top: 10px;
  font-weight: 500;
  cursor: pointer;
}
.modal-content-subtitle {
  margin-top: 20px !important;
  cursor: auto !important;
}
.modal-content {
  background-color: #fff;
  padding: 20px;
  max-width: 80vw;
  text-align: center;
  border-radius: 8px;
}
.app-links {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}
.app-links a {
  cursor: pointer;
  margin: 0 10px;
  display: inline-block;
}
.app-links img {
  height: 50px;
  cursor: pointer;
}
