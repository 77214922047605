.dropdown {
  position: relative;
  width: 100%;

  *::selection {
    background-color: transparent;
  }

  .dropdownButton {
    all: unset;
    cursor: pointer;
    width: 100%;

    div {
      background-color: #F5F5F5;
      display: flex;
      align-items: center;
      height: 50px;
      border: 2px #DCDCDC solid;
      border-radius: 4px;
      width: 100%;
      gap: 12px;

      // select last image

      img:first-of-type {
        margin-left: 18px;
      }

      img:last-of-type {
        margin-left: auto;
      }

      p {
        margin-left: 5px;
        color: gray;
        font-weight: 400;
        font-size: 12px;
      }
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
  }

  .dropdownMenu {
    z-index: 2;
    position: absolute;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    gap: 2px;
    border-radius: 8px;
    filter: drop-shadow(-5px 5px 15px rgba(0, 0, 0, 0.3));
    overflow: hidden;
    width: 100%;
    padding: 30px 10px;
    // center the menu
    left: 50%;
    transform: translateX(-50%);
    animation: openAnimation 0.2s ease-in-out;

    button {
      all: unset;
      cursor: pointer;
      padding: 12px;
      transition: 0.2s;
      background-color: white;
      font-weight: 500;
      font-size: 12px;
      color: #666666;
      text-align: start;
      display: flex;
      align-items: center;
      gap: 10px;

      &:hover {
        background-color: #C0C0C0;
      }
    }
  }
}

@keyframes openAnimation {
  0% {
    opacity: 0;
    transform: translateY(-10px) translateX(-50%);
  }

  100% {
    opacity: 1;
    transform: translateY(0) translateX(-50%);
  }
}