.selectList {
  border: 2px solid #DCDCDC;
  border-radius: 4px;
  background: #F5F5F5;
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
  cursor: pointer;
  position: relative;

  .firstIcon {
    margin-left: 18px;
  }

  .lastIcon {
    margin-left: auto;
    margin-right: 18px;
  }

  p {
    font-weight: 400;
    font-size: 12px;
    color: #484848;
    margin-left: 30px;

  }


  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
  }

  .options {
    z-index: 2;
    position: absolute;
    top: calc(100% + 10px);
    width: 100%;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    max-height: 200px;
    overflow-y: auto;

    button {
      all: unset;
      width: 100%;
      box-sizing: border-box;
      padding: 10px 30px;
      font-weight: 400;
      font-size: 12px;
      color: #484848;
      background: #F5F5F5;
      border: 2px solid #DCDCDC;
      border-top: none;
      cursor: pointer;
      text-align: left;

      &:hover {
        background: #DCDCDC;
      }
    }

    .selected{
      background: #DCDCDC;
    }
  }
}

